import React, { Component } from 'react';
import styled from 'styled-components';

class Spinner extends Component {
  render() {
    return (
      <div id="container" className={this.props.className}>
        <img
          src="https://9thwave-cycling.com/wp-content/uploads/4-Shore-Black-front-A-600x600.png"
          className="thing-to-spin"
        />
      </div>
    );
  }
}

export default styled(Spinner)`
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 5;
  width: 100%;
  ${p => (!!p.show ? 'left: 0;' : 'left:5000px;')}
  transition: left 2s ease-in;
  height: calc(100vh - 80px);
  s @-webkit-keyframes spin-me {
    0% {
      -webkit-transform: rotateZ(0deg);
      transform: rotateZ(0deg);
    }
    100% {
      -webkit-transform: rotateZ(360deg);
      transform: rotateZ(360deg);
    }
  }

  @keyframes spin-me {
    0% {
      -webkit-transform: rotateZ(0deg);
      transform: rotateZ(0deg);
    }
    100% {
      -webkit-transform: rotateZ(360deg);
      transform: rotateZ(360deg);
    }
  }

  .thing-to-spin {
    margin: auto;
    -webkit-animation-name: spin-me;
    animation-name: spin-me;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
`;
