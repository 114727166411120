import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
let Skycons;
if (typeof window !== 'undefined') {
  Skycons = require('skycons')(window);
}

export default class ReactSkycons extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    autoplay: PropTypes.bool
  };

  static defaultProps = {
    color: null,
    autoplay: true
  };

  constructor(props) {
    super(props);
    this.state = {
      skycons: new Skycons({ color: this.props.color })
    };
  }

  componentDidMount() {
    const { skycons } = this.state;
    if (skycons.add) {
      skycons.add(ReactDOM.findDOMNode(this), this.props.icon);
    }

    if (this.props.autoplay) {
      skycons.play();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.state.skycons.set(ReactDOM.findDOMNode(this), nextProps.icon);
  }

  componentWillUnmount() {
    const { skycons } = this.state;
    skycons.pause();
    skycons.remove(ReactDOM.findDOMNode(this));
  }

  play() {
    this.state.skycons.play();
  }

  pause() {
    this.state.skycons.pause();
  }

  render() {
    const {
      /* eslint-disable */
      // to remove unnecessary props
      color,
      autoplay,
      icon,
      /* eslint-enable */
      ...restPops
    } = this.props;

    const defaultStyle = {
      width: '100%',
      height: '100%'
    };

    return <canvas style={defaultStyle} {...restPops} />;
  }
}
