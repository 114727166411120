export default {
  atm: {
    lat: 50.340771,
    lng: 30.551846,
    name: 'Атмосфера'
  },
  track: {
    lat: 50.448667,
    lng: 30.505744,
    name: 'Велотрек'
  },
  truhanov: {
    lat: 50.490547,
    lng: 30.541658,
    name: 'Труханів'
  },
  truhanov2: {
    lat: 50.454546,
    lng: 30.534075,
    name: 'Труханів, пішохідний міст'
  },
  chabani: {
    lat: 50.334176,
    lng: 30.405348,
    name: 'Чабани'
  },
  vishgorod: {
    lat: 50.545202,
    lng: 30.485595,
    name: 'Вишгород'
  },
  feofan: {
    lat: 50.3526532,
    lng: 30.47232,
    name: 'Феофанія'
  },
  zhitomir: {
    lat: 50.455146,
    lng: 30.342199,
    name: 'Житомир'
  },
  dimer: {
    lat: 50.78299,
    lng: 30.327294,
    name: 'Сухолуччя'
  },
  bucha: {
    lat: 50.562103,
    lng: 30.217733,
    name: 'Буча'
  },
  agromat: {
    lat: 50.473148,
    lng: 30.350106,
    name: 'Агромат'
  },
  svyat: {
    lat: 50.460409,
    lng: 30.378665,
    name: 'Святошино'
  },
  belogorodka: {
    lat: 50.42045,
    lng: 30.24072,
    name: 'Белогородка'
  },
  bro: {
    lat: 50.467138,
    lng: 30.654467,
    name: 'Лісова'
  },
  bc: {
    lat: 50.210079,
    lng: 30.244717,
    name: 'Калинівка'
  },
  ds: {
    lat: 50.344287048318759,
    lng: 30.55172397461121,
    name: 'Домосфера'
  }
};
