import '../css/index.css';
import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';

import Layout from '../components/layout';
import Card from '../components/Card';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import moment from 'moment';
import Spinner from '../components/Spinner';

export const Background = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: ${p => p.color || '#0d181c'} !important;
  .flex-bar{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .white-text > div > div:before,  .white-text > div > div:after {
      border-color: white;
   }
  .white-text{
    width:350px;
    font-size: 18px;
    color: white;
    text-align: left;
    input ,hr{
      color: white !important;
      font-size: 18px;
      line-height: 1.2;
    }
  }
   @media (max-width: 1470px) {
     .hidden-md{
       display:none;
    }
 }
 
    @media (max-width: 1100px) {
     .hidden-sm{
       display:none;
    }
 }
  .flex-container{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
  }
  &:after {
    position: absolute;
    z-index: -1;
    background: linear-gradient(to bottom, rgba(13, 24, 28, 0), #0d181c 80%), url('${p =>
      p.img ||
      'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma.jpg?alt=media&token=286424d0-69af-4323-ad58-19d86f1c3a29'}');
    background-size: cover;
    background-position: 50% 10%;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    content: '';
  }
`;

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.props.firebase.base.listenToCollection('trainings', {
      context: this,
      state: 'bar',
      then() {
        this.props.trainings.getTrainings().then(trainings => this.setState({ trainings }));
      }
    });
  }

  state = {
    trainings: []
  };

  componentDidMount() {
    if (this.props.trainings) {
      this.props.trainings.getTrainings().then(trainings => this.setState({ trainings }));
    }
  }
  getImage = index => {
    if (this.props.data['f' + index]) {
      return this.props.data['f' + index].childImageSharp.sizes.src;
    }
  };

  render() {
    const currentUser = this.props.firebase?.auth?.currentUser?.uid;
    return (
      <Background img={this.getImage(8)}>
        <Spinner show={!this.state.trainings.length && false} />
        <a href="http://sis.in.ua">
          <div className="container">
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                color: 'white',
                marginTop: 10
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a href={'https://cyclinghouse.com.ua'} style={{ cursor: 'pointer' }}>
                  <img
                    src="https://static.tildacdn.com/tild3631-6632-4561-b464-663561666439/white_logo.png"
                    alt=""
                    style={{ height: 90 }}
                  />
                </a>
                <a href={'https://sis.in.ua/?promo=volovod'} style={{ cursor: 'pointer' }}>
                  <img
                    src="https://sis.in.ua/logo_header.svg"
                    alt="SiS Ukraine"
                    style={{ height: 70 }}
                  />
                </a>
              </div>
            </p>
          </div>
        </a>
        <div className={'flex-container'}>
          {this.state.trainings.map(train => (
            <Card
              train={train}
              currentUser={currentUser}
              goToTrain={this.props.trainings.goToTrain}
              key={train.id}
              removeTrain={this.props.trainings.removeTrain}
              editTrain={this.props.trainings.editTrain}
              defaultImage={this.getImage(moment(train.start).weekday())}
            />
          ))}
        </div>

        <h3
          style={{
            color: 'white',
            textAlign: 'center',
            fontWeight: 300,
            marginTop: 20,
            padding: '0 10px'
          }}
        >
          Кошти, зібрані за тренування, йдуть на підтримку
          <a href="https://www.facebook.com/hashtag/%D0%B1%D0%B5%D1%80%D0%B5%D0%B6%D1%96%D1%82%D1%8C%D1%81%D1%8F%D1%96%D1%97%D0%B1%D0%B0%D1%88%D1%82%D0%B5">
            {' '}
            ЗСУ
          </a>{' '}
          та <a href="https://www.facebook.com/TamDeTreba">волонтерів </a>🇺🇦
        </h3>
      </Background>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(LandingPage));

export default ({ data }) => (
  <Layout>
    <LandingPageWithFirebase data={data} />
  </Layout>
);
export const query = graphql`
  query ImagesForTrains {
    f1: file(relativePath: { eq: "v1s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    f2: file(relativePath: { eq: "v2s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    f3: file(relativePath: { eq: "v3s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    f4: file(relativePath: { eq: "v4s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    f5: file(relativePath: { eq: "v5s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    f6: file(relativePath: { eq: "v6s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    f7: file(relativePath: { eq: "v7s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    f8: file(relativePath: { eq: "v7s.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 400) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;
