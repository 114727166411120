import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FaMapMarker, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import GoogleMapReact from 'google-map-react';
import { withPrefix } from 'gatsby';
import moment from 'moment';
import locations from './locations';
import { getAdjustedPrice } from '../../BuisenessLogic/Trainings';

const StyledMarker = styled.img`
  width: 70px;
  height: 70px;
  position: absolute;
  top: -70px;
  left: -35px;
`;

const StyledX = styled(FaTimes)`
  font-size: 26px;
  color: black !important;
  path {
    color: black !important;
  }
`;

const Marker = () => <StyledMarker src={withPrefix('marker.png')} alt="" />;

const defaultDescription = `
  ✅ Чудовий асфальт
  ✅ Машина супроводу 
  ✅ Запасні колеса 
  ✅ Вода та їжа
  Обов'язково мати шолом, справний велосипед, одягатися по погоді!
    
  Перед тренуванням проводиться 
  вступний інструктаж і підготовка велосипедів.
  Тому необхідно прибути за 15 хвилин до початку.
`;

const description = {
  track: `
  Хороша альтернатива шосейним тренуванням
  З собою мати:
  Велоформу або футболку/шорти, рушник, веловзуття (MTB) або кросівки, воду.
  `,
  truhanov: `
  Тренування для початківців — це перші кроки, 
  щоби почати безпечно їздити в групі.
  Для досвідчених це відпрацювання технічних елементів, 
  встібування, розвороти, спринти.
  
  Обов'язково мати шолом, справний велосипед, одягатися по погоді!
  
  Перед тренуванням проводиться 
  вступний інструктаж і підготовка велосипедів.
  Тому необхідно прибути за 15 хвилин до початку.
  `
};

const getDescription = place =>
  place.trim() === 'track' || place.trim() === 'truhanov' ? description[place] : defaultDescription;

const TrainDetails = ({
  open,
  toggleDetails,
  train,
  eventImage,
  className,
  currentUser,
  goToTrain
}) => {
  const { resource, start, title, places, image, actual, price, place = 'atm', route } = train;
  const trainMarkedToVisit = resource.some(user => currentUser === user.id);
  const soldOut = places <= 0;
  const coordinates = locations[place];

  const showButton = (trainMarkedToVisit || !soldOut) && currentUser && actual;
  const props = {
    center: coordinates,
    zoom: 14
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" className={className}>
      <img src={withPrefix('marker')} alt="" />
      <StyledX onClick={() => toggleDetails(false)} />
      <div style={{ height: '30vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCsa8ybS1GQ3ShgBTHdKODfQ0QmdJ5tFO4' }}
          defaultCenter={props.center}
          defaultZoom={props.zoom}
        >
          <Marker {...coordinates} text={'Sigma start'} />
        </GoogleMapReact>
      </div>
      <DialogContent className={'dialog'}>
        <h2 id="form-dialog-title-train">{title}</h2>
        <p>{getDescription(place)}</p>
        <Typography component="ol">
          {[...resource].map(({ username, photoURL, id }) => {
            const src = photoURL.includes('graph') ? photoURL + '?width=50' : photoURL;
            return (
              <li
                key={id}
                onClick={() => {
                  window.location = `/rating?user=${id}`;
                }}
                style={{ cursor: 'pointer', fontSize: 15, padding: '1px, 0' }}
              >
                {photoURL && (
                  <Avatar
                    src={src}
                    style={{
                      verticalAlign: 'middle',
                      width: 30,
                      height: 30,
                      display: 'inline-block',
                      marginRight: 2,
                      backgroundColor: 'white'
                    }}
                  />
                )}
                {username}
              </li>
            );
          })}
        </Typography>
        <Typography component="p" style={{ textAlign: 'center', padding: 10 }}>
          {route && (
            <Button
              onClick={e => e.stopPropagation()}
              href={route}
              target="_blank"
              color="primary"
              style={{ fontSize: 14 }}
              title={'Переглянути у Strava'}
            >
              Маршрут
              <svg className="icon icon-strava" style={{ height: 15, marginLeft: -10 }}>
                <use xlinkHref={withPrefix('assets/img/sprite/sprite.svg#ic-strava')} />
              </svg>
            </Button>
          )}
          {coordinates && (
            <Button
              onClick={e => e.stopPropagation()}
              href={`http://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`}
              target="_blank"
              color="primary"
              style={{ fontSize: 14 }}
              title={'Переглянути у Google maps'}
            >
              <span>
                {coordinates.name}{' '}
                <FaMapMarker
                  className="icon icon-strava"
                  style={{ width: 13, height: 13, marginLeft: -10 }}
                />
              </span>
            </Button>
          )}
        </Typography>

        <Typography
          className={'bottom'}
          component="span"
          style={{ fontSize: '16px', fontWeight: 500, fontStyle: 'italy' }}
        >
          {showButton ? (
            <Button
              size="small"
              color="primary"
              onClick={() => {
                goToTrain(train, currentUser);
              }}
            >
              {trainMarkedToVisit ? 'Скасування' : 'Записатися'}
            </Button>
          ) : (
            <span />
          )}
          <span id="data">
            🗓{moment(start).format('DD MMMM HH:mm')}
            {price && <span> 🤑{getAdjustedPrice(price, start)} грн</span>}
          </span>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default styled(TrainDetails)`
  .dialog {
    color: white;
    background-color: #202527;
  }
  * {
    color: white;
  }

  svg {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 999;
  }
  #form-dialog-title-train {
    width: 100%;
    text-align: center;
  }
  > div > div {
    width: 100vw;
    max-height: calc(100% - 20px);
    margin: 8px;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (min-width: 400px) {
    p {
      white-space: pre;
    }
    #data {
      font-size: 14px;
    }
  }
  #data {
    font-size: 12px;
  }
`;
